<template>
  <div>
    <Navigation/>
    <v-container>
      <v-alert v-if="errorMessage" dense border="top" color="error" dark>
        {{ errorMessage }}
      </v-alert>
      <v-btn v-if="!openPricesAndParameters && !loadingCreate" block color="primary" @click="openPricesAndParameters=true">{{ this.selectedLanguage['newrailway_change_params'] }}</v-btn>
      <v-btn v-if="!openPricesAndParameters && loadingCreate" style="pointer-events: none;" block color="primary" @click="openPricesAndParameters=true">{{ this.selectedLanguage['newrailway_change_params'] }}</v-btn>
      <v-card v-if="openPricesAndParameters" class="elevation-0">
        <v-card-text>
          <PricesAndParametersTable configType="railway"/>
          <br>
          <v-row justify="center">
            <v-btn :color="color" dark text @click="openPricesAndParameters=false">{{ this.selectedLanguage['newrailway_change_params_close'] }}</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="configs.length>0" class="elevation-22">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <v-card-title>{{this.selectedLanguage['newrailway_card_title']}}</v-card-title>
          <v-tooltip max-width="600" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
              <v-icon style="margin-left: 10px;">
                mdi-information-outline
              </v-icon>
              </v-btn>
            </template>
            <span>{{ selectedLanguage['help_text_4'] }}</span>
          </v-tooltip>
        </div>
        <v-card-text>
          <form>
            <v-select v-model="configId" clearable :items="configs" item-text="name" item-value="id" :label="selectedLanguage['newrailway_card_params_textfield_title']"/>
            <v-text-field v-model="title" :label="selectedLanguage['newrailway_card_title_textfield_title']" required/>
            <v-text-field v-model="description" :label="selectedLanguage['newrailway_card_description_textfield_title']"/>
            <v-text-field v-model="owner" :label="selectedLanguage['newrailway_card_owner_textfield_title']"/>
            <v-row justify="center">
              <v-btn v-if="configId" :loading="loadingCreate" color="primary" @click="newCalc()">{{ this.selectedLanguage['newrailway_card_btn_crete_title'] }}</v-btn>
              <v-btn :color="color" dark text @click="$router.push('/')">{{ this.selectedLanguage['btn_cancel_title'] }}</v-btn>
            </v-row>
          </form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters , mapActions } from 'vuex'
  import Navigation from '../../Navigation'
  import PricesAndParametersTable from '../PricesAndParametersTable'
  import { portuguese } from '../../../languages/pt-br.js'
  import { english } from '../../../languages/en'
  
  export default {
    name: 'NewRailway',
    components:{Navigation, PricesAndParametersTable},
    computed: {
      helper(){
        return this.$store.getters.helper
      },
      configs () {
        var config = this.$store.getters.configsByType('railway')
        for(let i = 0; i < config.length; i++){
          if(config[i].deprecated === true || config[i].language != this.profile['language']){
            delete config[i]
          }else{
            //nothing
          }
        }
        return config
      },
      ...mapGetters(['profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        }
    },
    async mounted(){
      await this.getConfigs()
      await this.initTittleDesc()
    },
    data: () => ({
      configId: undefined,
      openPricesAndParameters: false,
      errorMessage: undefined,
      loadingCreate: false,
      title: 'Rail project',
      description: 'Rail project description',
      owner: "",
      type: 1,
      color: '#0A7373',
      baseLayer: undefined,
      sendLanguage: 'pt-br',
      languagesItems: [
        'pt-br',
        'en'
      ],
    }),
    watch: {
      async currency (val) {
        this.currency = val
        this.exchange = this.exchanges[this.currency]
      },
      configId: {
        handler() {
          if (this.configId != undefined && Array.isArray(this.configs)) { // Verifica se é um array
            for (let i = 0; i < this.configs.length; i++) {
              if (this.configs[i] && this.configs[i]['id'] != undefined) { // Verifica se this.configs[i] existe
                if (this.configs[i]['id'] == this.configId) {
                  this.description = this.configs[i]['name']
                }
              }
            }
          }
        }
      },
    },
    methods: {
      initTittleDesc(){
        this.title = this.selectedLanguage['newrailway_title']
        this.description = this.selectedLanguage['newrailway_description']
        this.owner = this.profile['first_name']
      },
      ...mapActions(['createCalc', 'changeStatus','getConfigs']),
      async newCalc(){
        this.errorMessage = undefined
        this.loadingCreate = true
        const payloadCalc = {
          "description": this.description,
          "title": this.title,
          "company": this.owner,
          "refers_to": "",
          "code": "",
          "continent": "",
          "type_id": this.configId
        }
        const res = await this.createCalc(payloadCalc)

        if(res.id){
          await this.changeStatus({calcId: res.id, payload: {"status": "Init", "step_name": "Adjust prices", "step": 2}})
          this.$router.push('/type/railway/'+res.id)
        }
        else{
          this.errorMessage = res
        }
        this.loadingCreate = false
      },
    },
  }
</script>

<style scoped>
.border {
  border: 2px dashed orange;
}
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
